.react-grid-item {
  overflow: auto !important;
}

a:not([href]) {
  color: #fff;
}

a:not([href]):hover {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

td {
  border-top: 1px solid grey !important;
  padding: 0.25rem 0.25rem !important;
}
